import React from 'react'

import { useLocation } from 'react-router-dom'

import { LOGIN_PROVIDER } from 'definitions'
import { useStores } from 'stores'
import SsoLogin from './SsoLogin'

import InterparkingLogo from 'assets/images/clients/interparking.svg'

export default ({ loginProvider }) => {
  const { oidcStore } = useStores()
  const location = useLocation()
  let logo = null

  if (loginProvider === LOGIN_PROVIDER.INTERPARKING) {
    logo = InterparkingLogo
  }

  return (
    <SsoLogin
      ssoStore={oidcStore}
      loginProvider={loginProvider}
      loginUrl={location.pathname}
      location={location}
      logo={logo}
    />
  )
}
