import React from 'react'

import { useLocation } from 'react-router-dom'

import { LOGIN_PROVIDER } from 'definitions'
import { useStores } from 'stores'
import SsoLogin from './SsoLogin'

import BelfiusLogo from 'assets/images/clients/belfius.png'
import GoedLogo from 'assets/images/clients/goed.svg'
import NunhemsLogo from 'assets/images/clients/nunhems.jpg'

export default ({ loginProvider }) => {
  const { saml2Store } = useStores()
  const location = useLocation()
  let logo = null

  if (loginProvider === LOGIN_PROVIDER.BELFIUS) {
    logo = BelfiusLogo
  } else if (loginProvider === LOGIN_PROVIDER.GOED) {
    logo = GoedLogo
  } else if (loginProvider === LOGIN_PROVIDER.NUNHEMS) {
    logo = NunhemsLogo
  }

  return (
    <SsoLogin
      ssoStore={saml2Store}
      loginProvider={loginProvider}
      loginUrl={location.pathname}
      location={location}
      logo={logo}
    />
  )
}
