import { LOGIN_PROVIDER, ROUTES } from 'definitions'

export const isSsoSaml = loginProvider => {
  return [LOGIN_PROVIDER.BELFIUS, LOGIN_PROVIDER.GOED, LOGIN_PROVIDER.NUNHEMS].includes(loginProvider)
}

export const isSsoPath = pathname => {
  return [ROUTES.LOGIN, ROUTES.LOGIN_BELFIUS, ROUTES.LOGIN_GOED, ROUTES.LOGIN_INTERPARKING, ROUTES.LOGIN_NUNHEMS].includes(
    pathname
  )
}
